/* FONTS */
@font-face {
  font-family: "Roughmarker";
  font-display: auto;
  src: local("Roughmarker"),
    url("../src/Fonts/Roughmarker.ttf") format("truetype");
}
@font-face {
  font-family: "Cormorant Garamond";
  font-display: auto;
  src: local("Cormorant Garamond"),
    url("../src/Fonts/CormorantGaramond-Bold.ttf") format("truetype");
}

body {
  /* background-attachment: fixed;  */
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  /* background-image: url("/src/assets/background-image.jpg"); */
  background-color: rgb(255, 255, 255);
}

/* About me  */

.about-me-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  height: 80vh;
}
.about-me-box {
  text-align: center;
  max-width: 500px;
  max-height: auto;
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.328);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.624);
}

.profile-photo {
  margin-left: 20px;
  border-radius: 10px;
  width: 500px;
  height: 500px;
  padding: 40px;
  object-fit: cover;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.624);
}

.aboutme {
  font-size: 3vw;
  color: #333;
  letter-spacing: 5px;
  font-family: "Cormorant Garamond";
  margin-bottom: 10px;
  height: 10vh;
  margin-right: -10px;
  white-space: nowrap;
  text-align: center;
  margin-top: 12px;
}
.aboutme-h3 {
  font-size: 20px;
  font-family: "Cormorant Garamond";
  margin-top: -5vh;
  margin-bottom: 6vh;
  white-space: nowrap;
  text-align: center;
}

.aboutme-h3-centered {
  margin-left: 1vw;
  margin-top: -3vh;
  padding: 1vw;
  font-family: "Cormorant Garamond";
  font-size: 1vw;
}

.aboutme-p {
  font-family: "Cormorant Garamond";
  line-height: 1.3;
  letter-spacing: 2px;
  text-align: center;
  margin-left: 1.5vw;
}

/* End about-me styles */

h2 {
  text-align: center;
  font-family: "Cormorant Garamond";
  font-size: 50px;
  letter-spacing: 10px;
  padding: 30px;
  border-top: 1px solid #000000;
  margin-left: 90px;
  margin-right: 90px;
}

/* h1 {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: "Roughmarker";
  font-size: 12vw;
  letter-spacing: 3vw;
  padding-bottom: 6vh;
  margin-bottom: 5vh;
  color: rgba(255, 255, 255, 0.579);
} */

h1 {
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: "Roughmarker";
  font-size: 12vw;
  letter-spacing: 3vw;
  padding-bottom: 6vh;
  margin-bottom: 5vh;
  color: rgba(255, 255, 255, 0.579);
  /* color: transparent;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.579),
    rgba(255, 255, 255, 0.579)
  );
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  animation: backcolor 12s linear 1 forwards; /* Set animation-iteration-count to 1 and fill-mode to forwards */
}
/* @keyframes backcolor {
  50% {
    background-position: 100vw 0;
  }
  100% {
    background-position: 0 0;
  }
} */
p {
  font-family: "Cormorant Garamond";
  font-size: 20px;
}

/* NAVBAR */

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
}

.nav-icons {
  color: black;
  font-size: 30px;
  padding: 5px;
}
.nav-icons :hover {
  color: #ada9a9;
}

.icons-container {
  display: flex;
  align-items: center;
}

.links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-left: 100px;
}
.links {
  text-decoration: none;
  margin: 0 20px;
  padding: 30px;
  color: rgb(0, 0, 0);
  text-align: center;
  justify-content: center;
  font-family: "Cormorant Garamond";
  font-size: 25px;
  /* letter-spacing: 5px; */
}

.links-container a:hover {
  color: #ada9a9;
  font-style: italic;
}

.name-title {
  font-family: "Roughmarker";
  font-size: 25px;
}

.close-icon {
  display: none;
}
/* END OF NAVBAR */

/* HOME PAGE */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 87vh;
  background: url("../src/assets/home-page-photo.jpeg") no-repeat center/100%;
  background-position: center center;
  margin: 0;
  padding: 0;
}
@-moz-document url-prefix() {
  .home-container {
    height: 87.5vh;
  }
}

.centered-image {
  max-width: 100%;
  max-height: 100%;
}

/* END OF HOME PAGE */

/* GALLERY IMAGES*/

.gallery-container {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
  padding: 10px;
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 30px;
}
.gallery-item {
  margin: 10px;
}

.gallery-container img {
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 450px;
  margin: 1vw;
  /* border: 1px solid black; */
  border-radius: 5px;
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.624);
  transition: transform 0.4s ease;
  object-fit: cover;
}

.gallery-container img:hover {
  transform: scale(1.1);
}

#published img {
  width: 500px;
  height: 500px;
  object-fit: contain;
}

/* Change the opacity of the LightGallery overlay */
body .lg-backdrop {
  background-color: rgba(9, 9, 9, 0.692);
}
/* END OF GALLERY IMAGES */

/* VIDEOS */
.videos {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* height: 80vh; */
  flex-direction: column;
  margin-top: 5vh;
}

.videos video {
  width: 25vw;
  padding: 4vw;
  height: auto;
}
.videos a {
  text-decoration: none;
  color: black;
  font-family: "Cormorant Garamond";
  font-size: 3vw;
  margin-bottom: -1vh;
  border-top: 1px solid black;
}

/* FOOTER */
.footer {
  background-color: #333333e3;
  color: white;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
}

.footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.name {
  margin-top: 9px;
  font-family: "ChamperlainDemo.Regular.ttf";
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.instagram-link {
  text-decoration: none;
  color: white;
  margin-top: 9px;
  padding-left: 10px;
}

.instagram-icon {
  font-size: 24px;
}

/* END OF FOOTER */

@media screen and (max-width: 1024px) and (min-width: 200px) {
  body {
    overflow-x: hidden;

    /* background-image: url("/src/assets/background-image.jpg") no-repeat/center; */
  }

  h1 {
    text-align: center;
    font-size: 9vw;
    letter-spacing: 4vw;
    margin-bottom: -2vh;
    margin-right: -3vw;
  }

  /* h1 {
    text-align: center;
    font-size: 9vw;
    letter-spacing: 4vw;
    margin-bottom: 5vh;
    margin-right: -3vw;
    background-attachment: fixed;
  } */

  h2 {
    text-align: center;
    font-size: 5vw;
    letter-spacing: 1px;
    white-space: nowrap;
    padding: 20px 0;
    margin: 30px;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    position: sticky;
    top: 0;
    background-color: #fbfbfb6c;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.624);
  }

  .navbar-container.menu-toggle {
    z-index: 1000;
  }

  .nav-icons {
    font-size: 20px;
  }
  .bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
    transition: all 0.3s ease;
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
    padding: 0px;
    position: fixed;
    right: 20px;
    top: 16px;
    z-index: 1001;
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    background-color: #4e4747f0;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 70%;
    max-width: 300px;
    padding: 20px;
    margin-top: 60px;
    transform: translateX(100%);
    transition: all 0.3s ease;
  }

  .links-container.show .menu-items {
    transform: translateX(0);
  }

  .links {
    text-decoration: none;
    padding: 10px 0;
    color: #dbd9d9;
    font-weight: 900;
    font-size: 20px;
    transition: all 0.3s ease;
  }

  .close-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 7px;
    cursor: pointer;
    font-size: 30px;
    color: #333;
    transition: all 0.3s ease;
  }

  .home-container {
    text-align: center;
    height: 85vh;
    background-position: center center;
  }

  .name-title {
    display: none;
  }

  .gallery-container {
    text-align: center;
    margin-bottom: 5vh;
  }

  .gallery-container img {
    width: 100px;
    height: 100px;
  }

  .gallery-container img:hover {
    transform: none;
  }

  #published img {
    width: 100px;
    height: 100px;
  }

  .videos {
    padding: 5vw;
    margin: 0;
  }

  .videos video {
    width: 60vw;
  }

  .videos a {
    font-size: 5vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  .about-me-container {
    padding: 50px;
    display: flex;
    flex-direction: column;
    margin-top: -2vh;
  }

  .about-me-box {
    max-width: 90%;
    max-height: 50%;
    margin-top: 10px;
    margin-bottom: 5vh;
    text-align: center;
  }

  .profile-photo {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    margin-right: 5vw;
  }

  .aboutme {
    font-size: 8vw;
    margin-left: -4vw;
    margin-top: -2vh;
    text-align: center;
    justify-content: center;
    font-family: "Cormorant Garamond";
  }

  .aboutme-h3-centered {
    margin-left: 2vw;
    font-family: "Cormorant Garamond";
    font-size: 3vw;
    height: 5vh;
  }

  .aboutme-h3 {
    margin-left: 2vw;
    display: flex;
    justify-content: center;
    margin-top: -5vh;
    font-family: "Cormorant Garamond";
    font-size: 3vw;
  }

  .aboutme-p {
    margin-left: 2vw;
    height: 2vh;
    font-size: 3vw;
    font-family: "Cormorant Garamond";
  }
}
